
import $ from 'jquery';

$(function () {

  $("<span></span>").insertAfter(".work p");
  $("<span></span>").insertBefore(".work p");

});

