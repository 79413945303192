<template>
  <div class="content">
    <section class="topic4">
      <img
        src="@/assets/imags/thank_you.svg"
        class="img-fluid"
        width="244.62"
        height="280.12"
      />

      <p class="p1">شكرا لك</p>
      <p class="p2">تمت عملية حجز الاستشارة بنجاح وسيتم التواصل معك لاحقاَ</p>

      <a href="/">العودة للصفحة الرئيسية</a>
    </section>
  </div>
</template>

<script>
export default {};
</script>


<style scoped>
.content .topic4 {
  text-align: center;
  margin-top: 20px;
  z-index: -5;
}

.topic4 p {
  font-family: fontm;
  font-size: 22px;
  margin: 0;
}

.topic4 .p1 {
  padding: 30px 0px 10px 0px;
}
.topic4 .p2 {
  padding: 10px 0px 40px 0px;
}

.topic4 a {
  color: #d21241;
  font-family: fontm;
  font-size: 22px;
  text-decoration: none;
  border-bottom: 4px solid #d21241;
}

@media (max-width: 1200px) {
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
}

/* animation*/

.backheadimage {
  animation-delay: 0.4s;
}

.backheadimage img {
  animation-delay: 0.4s;
}
</style>

