<template>
  <div class="content">
    <div class="backheadimage">
      <div class="container headerCont">
        <img
          id="aboutImage"
          src="@/assets/imags/financ.svg"
          class="img-fluid animate__animated animate__fadeInBottomRight"
          width="450"
          height="370"
        />
        <h2 class="animate__animated animate__fadeInBottomRight">التمويل</h2>
      </div>
    </div>

    <section class="topic2 animate__animated animate__fadeInBottomRight">
      <div class="container">
        <div class="row">
          <div class="financingTitle">
            <h3 v-text="financing_page_title"></h3>
            <p v-html="financing_page_description"></p>
          </div>
        </div>
      </div>

      <div class="container p-0">
        <div
          v-for="financing in financings"
          :key="financing.id"
          class="financingGroup"
        >
          <div class="row">
            <div class="img col-lg-2">
              <img class="financingImage" :src="financing.image" />
            </div>
            <div class="insideGroup col-lg-10">
              <h4 v-text="financing.name"></h4>
              <div
                v-html="financing.description"
                class="financingGroupDescription"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import request from "../http/Request";

export default {
  data() {
    return {
      financings: [],
      financing_page_title: "",
      financing_page_description: "",
    };
  },
  async created() {
    const financing = (await request.financing()).data;

    this.financings = financing.data.financings;

    this.financing_page_title = financing.page_information.financing_page_title;

    this.financing_page_description =
      financing.page_information.financing_page_description;
  },
};
</script>

<style scoped>
.content .backheadimage {
  background-image: url(../assets/imags/Group_2331.svg);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 108%;
  min-height: 700px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
}
.content .backheadimage .headerCont {
  position: relative;
  top: 22%;
  background-size: contain;
}
.content .backheadimage .headerCont img {
  position: relative;
  margin: 20px;
  right: 4%;
  background-size: contain;
}
.backheadimage .container h2 {
  color: white;
  text-align: right;
  position: absolute;
  top: 40%;
  right: 20%;
  font-family: fontm;
  font-size: 30px;
}
.topic2 {
  text-align: right;
  margin-bottom: -20px;
}

.financingTitle {
  margin: 10px 10px 60px 10px;
  word-wrap: break-word;
}

.financingTitle h3 {
  font-family: fontB;
  font-size: 33px;
  text-align: center;
  margin-bottom: 30px;
  color: #a1080a;
}

.financingImage {
  max-height: 140px;
  max-width: 120px;
}

.topic2 p {
  font-family: fontl;
  font-size: 22px;
  text-align: center;
  line-height: 2;
  margin: 20px;
  word-wrap: break-word;

  padding: 0px 0px 0px 0px;
}
.financingGroup {
  margin-top: 40px;
}
.financingGroup h4 {
  font-family: fontB;
  font-size: 20px;
  margin: 0px 0px 20px 0px;
}
.financingGroupDescription {
  font-family: fontm;
  font-size: 18px;
  /* text-align: right; */
  line-height: 1.6;
  color: #828282;
  margin: 0;
  padding: 0;
}

@media (min-width: 1367px) {
  .content .backheadimage img {
    width: 580px;
    height: 430px;
  }
}
@media (max-width: 992px) {
  .content .backheadimage {
    height: 180%;
  }
  .backheadimage .headerCont h2 {
    top: 0%;
    right: auto;
  }
  .topic2 {
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }
  .financingGroup p {
    text-align: center;
  }
}
@media (max-width: 768px) {
  .backheadimage .headerCont h2 {
    top: 0%;
    right: auto;
  }
  .content .backheadimage .headerCont {
    top: 22%;
    background-size: contain;
  }
  .content .backheadimage .headerCont img {
    position: static;
    right: 0%;
    margin: auto;
    margin-top: 80px;
  }
}
@media (max-width: 576px) {
  .backheadimage .headerCont h2 {
    top: 0%;
    right: 28%;
  }
  .content .backheadimage .headerCont {
    top: 18%;
  }
  .content .backheadimage .headerCont img {
    position: static;
    width: 100%;
    height: 280px;
    margin: auto;
    margin-top: 60px;
    padding-top: 60px;
  }
}
</style>