<template>
  <div class="content">
    <video
      :src="video_path"
      class="vdback"
      autoplay
      loop
      muted
      playsinline="playsinline"
    ></video>

    <section class="topic2">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 who-are-we">
            <div>
              <p>نبذة عن أصداء</p>
            </div>
          </div>
          <div class="col-lg-5 col-md-12 tera-image2">
            <img
              src="@/assets/imags/about_us.svg"
              width="321.66px"
              height="249.52px"
            />
          </div>
          <div class="col-lg-7 col-md-12 tera-image">
            <p v-text="who_are_we"></p>
          </div>
        </div>
      </div>
    </section>

    <section class="topic3">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 who-are-we">
            <div>
              <p>خدماتنا</p>
            </div>
          </div>
          <div class="Services-box">
            <div class="container">
              <div class="row">
                <div v-for="service in services" :key="service.id" class="box">
                  <img :src="service.image" />
                  <p>{{ service.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="topic5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 who-are-we">
            <div>
              <p>شركائنا</p>
            </div>
          </div>
          <div
            v-for="partner in partners"
            :key="partner.id"
            class="partner d-flex justify-content-center"
          >
            <img :src="partner.image" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import request from "../http/Request";

export default {
  name: "Home",

  data() {
    return {
      services: [],
      partners: [],
      who_are_we: "",
      video_path: "",
    };
  },
  async created() {
    const home = (await request.home()).data;

    this.services = home.data.services;

    this.partners = home.data.partners;

    this.who_are_we = home.page_information.who_are_we;

    this.video_path = home.page_information.video_path;
  },
};
</script>

<style scoped>
video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text {
  position: relative;
  z-index: 10;
  padding-right: 8%;
}
.text p {
  color: white;
  text-align: right;
}
.tera-head {
  font-family: fontb;
  font-size: 73px;
}
.tera-head2 {
  font-family: fontl;
  font-size: 30px;
}
.topic2 {
  margin-bottom: 200px;
}
.tera-image {
  background-repeat: no-repeat;
  background-size: auto;
  height: 400px;
  padding-top: 20px;
  background-size: contain;
}
.tera-image p {
  text-align: right;
  font-family: fontl;
  word-spacing: 4px;
  font-size: 18px;
  line-height: 2.2rem;
  padding-right: 10px;
}
.tera-image2 img {
  position: relative;
  left: 110px;
  bottom: 80px;
}
.who-are-we {
  text-align: center;
}
.who-are-we div {
  margin: auto;
  color: #000000;
  font-family: fontb;
  font-size: 1.7rem;
  padding: 0;
  padding-right: 28px;
  width: 20%;
  height: 28px;
  margin-bottom: 80px;
  background-image: linear-gradient(
    to top,
    rgba(247, 212, 220, 0.9),
    rgba(255, 255, 255, 1)
  );
}

.who-are-we div p {
  line-height: 1rem;
  padding-left: 10px;
}
.topic3 {
  padding-bottom: 40px;
  background-image: linear-gradient(
    to top,
    rgba(252, 243, 245, 1),
    rgba(254, 254, 254, 1)
  );
  height: 100%;
}
.Services-box {
  text-align: center;
  margin-bottom: 60px;
}
.box {
  width: 18%;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0px 90px 0px 90px;
  padding: 30px 26px 12px 26px;
  font-family: fontm;
  word-spacing: 0.1rem;
  margin: 2% 1% 2% 1%;
  background-color: white;
}
.box img {
  height: 80px;
}
.box p {
  padding-top: 28px;
}

.topic5 {
  text-align: center;
  margin-bottom: 10px;
  padding: 10px;
  padding-top: 120px;
}
.topic5 img {
  height: 80px;
  cursor: pointer;
}

.topic5 .partner {
  align-content: center;
  margin: 50px;
}

.slide .carousel-indicators li {
  border: none;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  background-image: linear-gradient(
    to top,
    rgba(108, 9, 34, 1),
    rgba(209, 18, 65, 1)
  );
  box-shadow: 0px 0px 2px 6px #f1d0d8;
  margin-right: 14px;
}
.slide .carousel-indicators {
  bottom: -70px;
}
.slide ol.carousel-indicators {
  padding: 0;
}
/*footer*/

@media (max-width: 1200px) {
  .tera-image p {
    padding-right: 0rem;
  }
  .who-are-we div {
    width: 35%;
    height: 28px;
  }
  .topic4 .topic4-back-img > :first-child {
    padding-right: 100px;
  }

  .box {
    padding: 16px 16px 10px 16px;
    word-spacing: 0.1rem;
  }
  .box p {
    padding-top: 20px;
  }
}



@media only screen and (max-width: 768px) {

  .who-are-we {
    margin-top: 50%;
  }
}


@media only screen and (max-width: 280px) {

  .who-are-we {
    margin-top: 150%;
  }
}



@media (max-width: 992px) {
  .who-are-we div {
    font-size: 1.1rem;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-right: 0px;
    width: 60%;
    height: 28px;
  }

  .who-are-we div p {
    padding: 0;
  }
  .who-are-we2 {
    margin-bottom: 40px;
  }
  .tera-image2 {
    text-align: center;
    margin-bottom: 40px;
  }
  .tera-image2 img {
    position: static;
    width: 300px;
    height: 250px;
  }
  .tera-image p {
    padding: 12px;
    text-align: center;
    line-height: 1.6rem;
  }
  .tera-image {
    margin-bottom: 100px;
  }
  .slaid-button .myWork {
    width: 60%;
    margin: auto;
    margin-bottom: 16px;
    margin-top: 10px;
  }
  .slaid-button .myWork p {
    font-size: 15px;
  }
  .slaid-button {
    display: block;
  }
  .topic4 .topic4-back-img > :first-child {
    padding-right: 10px;
    padding-top: 60px;
  }
  .box {
    width: 30%;
    margin: auto;
    margin-top: 20px;
  }

  /* .box p {
    padding-top: 18px;
    font-size: 16px;
  }*/
  .flexContent {
    display: block;
    background-color: white;
    border-radius: 50px;
    width: 80%;
    margin: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }
  .slaider-text {
    width: 100%;
    text-align: right;
    padding: 40px;
    font-size: 14px;
    word-spacing: 4px;
    line-height: 0;
  }
  .slaider-text p {
    line-height: 1.7;
  }
  .img-slaider {
    width: 100%;
    /* padding: 20px;
    padding-bottom: 30px;
    padding-top: 40px; */
    border-radius: 50px 50px 0px 0px;
  }
  .slaider-text > :first-child {
    font-size: 22px;
  }
  .img-slaider img {
    height: 110px;
    min-height: 110px;
  }
  .topic4 {
    margin-bottom: 350px;
  }
  .topic2 {
    margin-bottom: 50px;
  }
  .tera-image2 img {
    width: 100%;
  }
}
@media (max-width: 768px) {
  /* 
    
 video{
    position: absolute;
    top: -260px;
    left: -70px;
    width: 100%;
     height: 100%;
    min-height: 360px;
     min-width: 468px;
    object-fit: contain;
    
    
}*/
  .box img {
    width: 12rem;
    height: 8rem;
  }
  .box {
    padding: 26px 0px 10px 16px;
    width: 65%;
  }
  .topic2 {
    margin-bottom: 20px;
  }
  .tera-image {
    margin-bottom: 50px;
  }
  .carousel-indicators {
    margin-bottom: 0px;
  }
  .slaider-text {
    text-align: center;
    padding: 10px;
    font-size: 15px;
    word-spacing: 4px;
    line-height: 3;
    min-height: 300px;
  }
  .img-slaider {
    width: 100%;
    padding: 20px;
    padding-bottom: 30px;
    padding-top: 40px;
    border-radius: 50px 50px 0px 0px;
  }
  .topic4 {
    margin-bottom: 300px;
  }
}
@media (max-width: 576px) {
  .myWork {
    margin-top: 2px;
  }
  .who-are-we4 {
    margin-top: 80px;
  }
}
@media (max-width: 300px) {
  .box {
    padding: 26px 0px 10px 20px;
    width: 72%;
  }
  .tera-image p {
    font-size: 16px;
  }
}
@media (min-height: 900px) {
  video {
    width: 100%;
    height: 100%;
  }
}
</style>