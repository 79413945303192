<template>
  <div class="content">
    <div class="backheadimage img-fluid">
      <div class="container headerCont">
        <h2 class="animate__animated animate__fadeInBottomRight">
          نفتخر بأصداء بتقديم خدمات مميزة داخل المملكة و خارجها
        </h2>
        <div class="row row1 animate__animated animate__fadeInBottomRight">
          <ul class="col-lg-12">
            <div class="row">
              <li
                v-for="service in services"
                :key="service.id"
                v-text="service.name"
                class="col-lg-4 col-sm-6"
              ></li>
            </div>
          </ul>
        </div>
      </div>
      <div class="container">
        <div class="row boxses justify-content-center">
          <div
            class="choose animate__animated animate__fadeInBottomRight"
            id="show-box1"
            ref="showbox1"
            tabindex="0"
            @focus="showfirstBox"
          >
            <img src="@/assets/imags/view_service.svg" class="img-fluid" />
            <p>عرض الخدمات</p>
          </div>
          <div
            class="choose animate__animated animate__fadeInBottomRight"
            id="show-box2"
            ref="showbox2"
            tabindex="0"
            @focus="showSecondBox"
          >
            <img src="@/assets/imags/consultation.svg" class="img-fluid" />
            <p>طلب استشارة</p>
          </div>
        </div>
      </div>
    </div>

    <!--modal -->
    <ContactUsModal />
    <!--modal -->

    <div class="hasBox1" id="hasBox1">
      <div class="container">
        <div class="titleBox d-flex">
          <p>حجز موعد استشارة</p>
          <span
            >(قيمة الاستشارة
            <span class="enNumber" v-text="consulte_price"></span> ريال)</span
          >
        </div>

        <form @submit.prevent="store" class="consultation-form">
          <div class="row">
            <div
              class="
                request-a-consultation request-a-consultation1
                d-flex
                justify-content-between
                col-lg-12
              "
            >
              <p>بيانات الاستشارة</p>
              <a href="#"><i class="fa fa-angle-down"></i></a>
            </div>

            <div class="cont animated fadeIn col-lg-12" style="display: none">
              <div class="row">
                <div class="block col-lg-12">
                  <h3>مدة الاستشارة</h3>

                  <div
                    v-for="duration in durations"
                    :key="duration.id"
                    class="form-check"
                  >
                    <label
                      class="form-check-label container"
                      :for="duration.duration"
                    >
                      <input
                        v-model="duration_id"
                        class="form-check-input"
                        type="radio"
                        name="consultation-duration"
                        :id="duration.duration"
                        :value="duration.id"
                      />
                      {{ duration.duration }}
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>

                <div class="block col-lg-12">
                  <h3>مكان الاستشارة</h3>
                  <div class="form-check">
                    <label class="form-check-label container" for="online">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="consultation-place"
                        id="online"
                        value="1"
                        v-model="place"
                      />
                      عن بعد
                      <span class="checkmark"></span>
                    </label>
                  </div>

                  <div class="form-check">
                    <label
                      class="form-check-label container"
                      for="atTerraCompany"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="consultation-place"
                        id="atTerraCompany"
                        value="2"
                        v-model="place"
                      />
                      في مقر الشركة
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>

                <div class="block col-lg-12">
                  <div class="row">
                    <div class="col-lg-4 offset-lg-1">
                      <label class="form-check-label" for="counselingSubject"
                        >موضوع الاستشارة</label
                      >
                      <input
                        v-model="title"
                        type="text"
                        class="form-control inputStyle"
                        id="counselingSubject"
                        name="counselingSubject"
                      />

                      <div
                        class="error text-danger"
                        v-if="errors && errors.title"
                      >
                        {{ errors.title[0] }}
                      </div>
                    </div>

                    <div class="col-lg-4 selectIcon">
                      <label class="form-check-label" for="consultationTime">
                        وقت الاستشارة</label
                      >

                      <select
                        v-model="time_id"
                        id="jop_type"
                        name="consultationTime"
                        class="selectpicker inputStyle"
                      >
                        <option
                          v-for="time in times"
                          :key="time.id"
                          :value="time.id"
                          v-text="time.time"
                        ></option>
                      </select>

                      <i class="fa fa-angle-down" style="float: left"></i>

                      <div
                        class="error text-danger"
                        v-if="errors && errors.time"
                      >
                        {{ errors.time[0] }}
                      </div>
                    </div>

                    <div class="col-lg-4 offset-lg-1 selectIcon">
                      <label class="form-check-label" for="consultationDate"
                        >موعد الاستشارة</label
                      >
                      <br />
                      <br />
                      <datepicker
                        wrapper-class="selectpicker inputStyle"
                        id="text-datepicker"
                        input-class="form-control"
                        v-model="date"
                        dir="rtl"
                        :language="ar"
                        :disabledDates="disabledDates"
                      >
                      </datepicker>

                      <i class="fa fa-angle-down" style="float: left"></i>

                      <div
                        class="error text-danger"
                        v-if="errors && errors.date"
                      >
                        {{ errors.date[0] }}
                      </div>
                    </div>
                    <div class="col-lg-4 offset-lg-1">
                      <label class="form-check-label" for="peopleNumber">
                        عدد الاشخاص
                        <br />
                        <small>(مسموح خمسة اشخاص)</small>
                      </label>

                      <input
                        v-model="numberOfPeople"
                        type="number"
                        min="1"
                        max="5"
                        maxlength="1"
                        class="form-control inputStyle"
                        id="peopleNumber"
                        name="peopleNumber"
                      />

                      <div
                        class="error text-danger"
                        v-if="errors && errors.numberOfPeople"
                      >
                        {{ errors.numberOfPeople[0] }}
                      </div>
                    </div>

                    <div class="col-lg-4 offset-lg-1">
                      <label for="cv">إضافة مرفق</label>
                      <div class="file-up inputStyle">
                        <input
                          type="file"
                          id="fileUpload"
                          ref="fileUpload"
                          @change="viewFileName"
                        />
                        <span id="fileName" ref="fileName"></span>
                        <button
                          type="button"
                          id="customButton"
                          @click="openFileInput"
                        >
                          اختيار ملف
                        </button>
                      </div>
                      <p class="fileP">
                        الملفات المسموحة ( PDF ,PNG , JPG , DOCX )
                      </p>

                      <div
                        class="error text-danger"
                        v-if="errors && errors.file"
                      >
                        {{ errors.file[0] }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="
                request-a-consultation request-a-consultation2
                d-flex
                justify-content-between
                col-lg-12
              "
            >
              <p>البيانات الشخصية</p>
              <a href="#"><i class="fa fa-angle-down"></i></a>
            </div>

            <div class="cont animated fadeIn col-lg-12" style="display: none">
              <div class="row">
                <div class="block col-lg-12">
                  <div class="row">
                    <div class="col-lg-4 offset-lg-5">
                      <label class="form-check-label" for="name">الاسم </label>
                      <input
                        v-model="name"
                        type="text"
                        class="form-control inputStyle"
                        id="name"
                        name="name"
                      />

                      <div
                        class="error text-danger"
                        v-if="errors && errors.name"
                      >
                        {{ errors.name[0] }}
                      </div>
                    </div>

                    <div class="col-lg-4 offset-lg-5">
                      <label class="form-check-label" for="email"
                        >البريد الالكتروني
                      </label>
                      <input
                        v-model="email"
                        type="email"
                        class="form-control inputStyle"
                        id="email"
                        name="email"
                      />

                      <div
                        class="error text-danger"
                        v-if="errors && errors.email"
                      >
                        {{ errors.email[0] }}
                      </div>
                    </div>

                    <div class="col-lg-4 offset-lg-5">
                      <label class="form-check-label" for="phoneNumber"
                        >الجوال
                      </label>
                      <input
                        v-model="phone_number"
                        type="text"
                        class="form-control inputStyle"
                        id="phoneNumber"
                        name="phoneNumber"
                      />

                      <div
                        class="error text-danger"
                        v-if="errors && errors.phone_number"
                      >
                        {{ errors.phone_number[0] }}
                      </div>
                    </div>

                    <div class="col-lg-4 mt-3 offset-lg-5">
                      <!-- add recaptcha -->
                      <vue-recaptcha
                        :sitekey="sitekey"
                        :loadRecaptchaScript="true"
                        language="ar"
                        @verify="onVerify"
                        @expired="onExpired"
                      ></vue-recaptcha>
                      <div class="error text-danger" v-if="showRobotError">
                        يرجى التحقق أولا
                      </div>
                    </div>

                    <div class="bt col-lg-12 justify-content-start">
                      <button type="submit">ارسال</button>
                      <div class="error text-danger" v-if="isThereAnyErrors">
                        يوجد خطا في إدخال البيانات الرجاء التحقق من المدخلات
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="hasBox2" id="hasBox2">
      <div class="container">
        <div class="row">
          <div class="priceTitle">
            <h3>
              تقدم اصداء للاستشارات المالية مجموعة من الخدمات المتميزة لتلبية
              احتياجات عملائها
            </h3>
          </div>
        </div>
      </div>

      <div class="container">
        <div v-for="service in services" :key="service.id" class="priceGroup">
          <div class="row">
            <div class="img col-lg-2">
              <img class="serviceImage" :src="service.image" />
            </div>
            <div class="insideGroup col-lg-7">
              <h4 v-text="service.name"></h4>
              <div
                v-html="service.description"
                class="priceGroupDescription"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "../http/Request";
import ContactUsModal from "../components/ContactUsModal";
import axios from "../http/axios";
import $ from "jquery";
import datepicker from "vuejs-datepicker";
import { ar } from "vuejs-datepicker/dist/locale";
import dateformat from "dateformat";
import VueRecaptcha from "vue-recaptcha";

export default {
  components: {
    ContactUsModal,
    datepicker,
    VueRecaptcha,
  },
  data() {
    return {
      ar: ar,
      services: [],
      durations: [],
      times: [],
      consulte_price: 0,
      // Date picker past days
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },

      // order consulte attributes
      title: "",
      date: "",
      place: 1,
      numberOfPeople: "",
      name: "",
      email: "",
      phone_number: "",
      duration_id: null,
      time_id: null,
      errors: {},
      sitekey: process.env.VUE_APP_SITEKEY,
      robot: true,
      showRobotError: false,
    };
  },
  computed: {
    isThereAnyErrors() {
      return  !$.isEmptyObject(this.errors);
    },
  },
  methods: {
    uniqueId(question) {
      return question.content + question.id;
    },

    slideToggle(e) {
      const element = e.target;

      if (element.tagName == "DIV") {
        $(element.nextSibling).slideToggle();
      }

      if (element.tagName == "P" || element.tagName == "INPUT") {
        $(element.parentElement.nextSibling).slideToggle();
      }
    },
    store() {
      if (this.robot) {
        this.showRobotError = true;
        return;
      }
      let data = new FormData();

      data.append("title", this.title);

      data.append("place", this.place);
      data.append("numberOfPeople", this.numberOfPeople);
      data.append("name", this.name);
      data.append("email", this.email);
      data.append("phone_number", this.phone_number);
      data.append("duration_id", this.duration_id);
      data.append("time_id", this.time_id);

      if (this.date) {
        data.append("date", dateformat(this.date, "yyyy-mm-dd"));
      }

      let file = document.getElementById("fileUpload").files[0];
      if (file) {
        data.append("file", file);
      }

      let settings = { headers: { "content-type": "multipart/form-data" } };

      axios
        .post("consultes", data, settings)
        .then(() => {
          document.querySelector("form").reset();
          document.querySelector("#fileName").textContent = "";
          this.errors = {};
          this.title = "";
          this.date = "";
          this.place = 1;
          this.numberOfPeople = "";
          this.name = "";
          this.email = "";
          this.phone_number = "";
          this.duration_id = this.durations[0].id;
          this.time_id = this.times[0].id;
          this.$router.push("/thank-you");
        })
        .catch((errors) => {
          if (errors.response.status == 422) {
            this.errors = errors.response.data.errors;
            $(".request-a-consultation").show();
          }
        });
    },
    calculateTotal() {
      const selectedRadios = [];

      document.querySelectorAll(".calculate-price-radio").forEach((el) => {
        if (el.checked) {
          selectedRadios.push(el.value);
        }
      });

      const allChoices = this.selectedOptionsCheck.concat(selectedRadios);

      this.selectedChoices = allChoices;

      axios
        .post("calculate/price", {
          choices: allChoices,
        })
        .then((res) => {
          this.errors = {};
          this.totalPrice = res.data.price;
        })
        .catch((errors) => {
          if (errors.response.status == 422) {
            this.errors = errors.response.data.errors;
          }
        });
    },

    openFileInput() {
      this.$refs.fileUpload.click();
    },
    viewFileName(e) {
      this.$refs.fileName.innerHTML = e.target.value.split(/(\\|\/)/g).pop();
    },

    showfirstBox() {
      $(this.$refs.showbox2)
        .css({ opacity: "0.2", border: "none" })
        .animate({ opacity: 1 }, 1000);

      $(this.$refs.showbox1)
        .css({ border: "6px solid #D21241", outline: "none" })
        .animate({ opacity: 1 }, 1000);
      $(".hasBox2").show();
      $(".hasBox1").hide();
      window.location.href = "#hasBox2";
    },
    showSecondBox() {
      $(this.$refs.showbox1)
        .css({ opacity: "0.2", border: "none" })
        .animate({ opacity: 1 }, 1000);
      $("#show-box2")
        .css({ border: "6px solid #D21241", outline: "none" })
        .animate({ opacity: 1 }, 1000);
      $(".hasBox1").show();
      $(".hasBox2").hide();
      window.location.href = "#hasBox1";
    },
    onVerify() {
      this.robot = false;
      this.showRobotError = false;
    },
    onExpired() {
      this.robot = true;
    },
  },

  async created() {
    const data = (await request.OurServices()).data;

    this.services = data.services;

    this.durations = data.durations;

    this.times = data.times;

    this.consulte_price = data.consulte_price;

    // Default values
    this.duration_id = this.durations[0].id;

    this.time_id = this.times[0].id;
  },
  mounted() {
    document.querySelector("#text-datepicker").style.backgroundColor = "white";
    document.querySelector("#text-datepicker").style.borderBottom = "none";
    $(".request-a-consultation").on("click", function () {
      $(this).next().slideToggle();
    });
  },
};
</script>

<style scoped>
.block .inputStyle {
  border-radius: 4px !important;
  border: 0;
  box-shadow: 2px 0px 4px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 46px;
  background-color: white;
}
.block .inputStyle:focus {
  outline: none;
  box-shadow: 1px 1px 2px 3px rgba(242, 69, 111, 0.2);
}
.content .backheadimage {
  background-image: url(../assets/imags/Group_2331.svg);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 108%;
  min-height: 700px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
}
.content .backheadimage .headerCont {
  position: relative;
  top: 2%;
}

.serviceImage {
  max-height: 100px;
  max-width: 100px;
}

.backheadimage h2 {
  color: white;
  text-align: right;
  position: relative;
  top: 190px;
  /* right:12%;*/
  margin-right: 9%;
  font-family: fontm;
  font-size: 21px;
  z-index: 1;
  margin: auto;
}
.backheadimage .container {
  padding-left: 0;
  padding-right: 0;
}
.backheadimage ul {
  list-style: none;
  text-align: right;
  color: white;
  font-family: fontl;
  font-size: 16px;
  word-spacing: 1px;
}
.backheadimage .row1 {
  width: 70%;
  position: relative;
  top: 210px;
  z-index: 11;
  margin-right: 3.5%;
}
.backheadimage ul li::before {
  content: "\2022";
  left: 0.8em;
  margin-top: 1em;
  font-size: 35px;
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  top: 10px;
}
.backheadimage ul li {
  line-height: 1.2;
}
.backheadimage .choose {
  box-shadow: 2px 0px 8px 4px rgba(0, 0, 0, 0.2);
  margin: 4% 3% 4% 3%;
  border-radius: 20px;
  width: 18%;
  height: 200px;
  text-align: center;
  padding: 15px;
  background-color: white;
  cursor: pointer;
  z-index: 12;
  position: relative;
  top: 200px;
}
.backheadimage .choose img {
  max-width: 75%;
  cursor: pointer;
  height: auto;
  margin-bottom: 15px;
}
.backheadimage .choose p {
  font-family: fontm;
  font-size: 1vw;
}
.titleBox p {
  font-family: fontb;
  font-size: 22px;
  color: #010101;
  margin-left: 10px;
}
.titleBox span {
  font-family: fontl;
  font-size: 22px;
  color: #a1080a;
}
/*request-a-consultation*/
.request-a-consultation {
  padding: 15px 54px 15px 54px;
  text-align: right;
  background-image: linear-gradient(to right, #a1080a, #510405);
  cursor: pointer;
  margin: 50px 10px 10px 10px;
  text-align: right;
}
.request-a-consultation p {
  font-size: 22px;
  color: white;
  font-family: fontm;
  margin: 0;
}
.request-a-consultation i {
  font-size: 24px;
  color: white;
  margin: 0;
}
.request-a-consultation i {
  font-size: 40px;
  font-weight: 600;
}
.consultation-form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
}
.selectIcon i {
  font-size: 26px;
  color: #8a8686;
  margin: 0;
  position: relative;
  bottom: 38px;
  left: 10px;
}
.dateIcon i {
  font-size: 26px;
  color: #8a8686;
  margin: 0;
  position: relative;
  bottom: 54px;
  left: 10px;
}
.block label {
  margin: 10px;
  margin-top: 30px;
  font-size: 16px;
  font-family: fontl;
  color: #a8a8a8;
}
.block .file-up {
  padding: 10px;
  text-align: left;
}
.block p.fileP {
  font-family: fontl;
  font-size: 12px;
  text-align: right;
  padding-top: 2px;
  color: #a8a8a8;
  margin: 6px;
}
.date i {
  font-size: 24px;
  color: #8a8686;
  margin: 0;
  position: relative;
  text-align: left;
}
.datepicker-orient-bottom {
  font-size: 14px;
  font-family: Arial;
}
.datepicker {
  top: 1600px !important;
}
.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active {
  background-color: #a1080a;
}
.datepicker table tr td.active:hover,
.datepicker table tr td.active.highlighted:hover,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active {
  background-color: #a1080a;
}
.datepicker table tr td.today {
  background-color: #f67d9a;
}
.datepicker table tr td,
.datepicker table tr th {
  border-radius: 50%;
}
.datepicker table tr td.today:hover {
  background-color: #f67d9a;
  border-color: #f67d9a;
}
#fileUpload {
  display: none; /* do not display the actual file upload button */
}
#customButton {
  /* style the fake upload button */
  background: #fcedef;
  color: #a1080a;
  font-size: 14px;
  font-family: fontm;
  border: 0;
}
.hasBox1 {
  display: none;
}
.hasBox2 {
  display: none;
}
.titleBox2 p {
  font-family: fontb;
  font-size: 24px;
  color: #a1080a;
  text-align: right;
}

.cont {
  text-align: right;
  padding-right: 4%;
}
.service-form h3,
.consultation-form h3 {
  color: #a1080a;
  font-family: fontm;
  font-size: 20px;
  margin: 40px 0px 0px 0px;
}
input[type="checkbox"] {
  border: 3px solid #a1080a;
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  outline: none;
  padding-top: 2px;
}
input[type="checkbox"]:checked {
  border: none;
  background-color: #a1080a;
  background: #a1080a
    url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==")
    5px 5px no-repeat;
}
.form-check {
  margin-top: 20px;
  width: 100%;
}
.form-check label {
  margin-right: 40px;
  font-family: fontm;
  font-size: 20px;
  width: 99%;
}
.datepicker-form {
  opacity: 1 !important;
  width: 100% !important;
  background-color: white !important;
  border-bottom: none !important;
}
.form-check span {
  padding-right: 12px;
  font-family: fontl;
  font-size: 14px;
  color: #9e9e9e;
}
.form-check .enNumber {
  font-family: arial;
  padding: 2px;
}
.titleBox .enNumber {
  font-family: arial;
  padding: 2px;
}
.form-check .container {
  display: block;
  position: relative;
  padding: 0;
  /* margin-bottom: 12px;*/
  cursor: pointer;
  /*font-size: 22px;*/
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default radio button */
.form-check .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 2px;
  /* left: 896px;*/
  right: -40px;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid #a1080a;
}
.priceTitle {
  margin: 10px 10px 80px 10px;
}

.priceTitle h3 {
  font-family: fontm;
  font-size: 26px;
  text-align: center;
  margin: 0px;
  padding: 0px 0px 0px 0px;
  color: #a1080a;
}
.priceGroup {
  margin-top: 60px;
  text-align: right;
  width: 100%;
}
.priceGroup h4 {
  font-family: fontB;
  font-size: 24px;
  margin: 0px 0px 20px 0px;
}
.priceGroup p {
  font-family: fontm;
  font-size: 24px;

  line-height: 1.6;
  color: #828282;
  margin: 0;
  padding: 0;
}
.priceGroup .img {
  margin: 10px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:before {
  content: "";
  position: absolute;
  display: none;
}
/* Show the indicator (dot/circle) when checked */
.form-check .container input:checked ~ .checkmark:before {
  display: block;
}
/* Style the indicator (dot/circle) */
.form-check .container .checkmark:before {
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #a1080a;
}
.bt button {
  color: white;
  width: 40%;
  padding: 10px;
  border-radius: 50px;
  font-family: fontm;
  font-size: 16px;
  background-image: linear-gradient(to right, #a1080a, #510405);
  border: 1px solid #707070;
  margin: auto;
}
.bt button:focus {
  background-image: linear-gradient(to left, #a1080a, #510405);
}
.bt button:hover {
  background-image: linear-gradient(to left, #a1080a, #510405);
}
.bt {
  margin-top: 80px;
}
/*طلب عرض سعر الخدمات*/
.text-danger {
  font-size: 12px;
  text-align: right;
  margin: 0;
}
@media (min-width: 1367px) {
  .backheadimage h2 {
    top: 180px;
    font-size: 21px;
  }
  .backheadimage ul {
    font-size: 19px;
    word-spacing: 1px;
    margin-right: 50px;
  }
  .backheadimage .row1 {
    width: 110%;
    position: relative;
    top: 190px;
    z-index: 11;
    margin-right: 4.5%;
  }
  .backheadimage ul li::before {
    font-size: 50px;
  }
  .backheadimage .choose {
    width: 23%;
    height: auto;
    top: 200px;
  }
  .backheadimage .choose img {
    max-width: 80%;
  }
  .titleBox {
    margin-top: 10px;
  }
  .titleBox p {
    font-family: fontm;
    font-size: 27px;
    color: #010101;
  }
  .titleBox span {
    font-family: fontl;
    font-size: 27px;
    color: #d21241;
  }
}
@media (max-width: 1200px) {
  .backheadimage h2 {
    top: 180px;
    margin-right: 10%;
    font-size: 16px;
  }
  .backheadimage .row1 {
    position: relative;
    top: 180px;
    z-index: 11;
    margin-right: 6%;
  }
  .backheadimage ul {
    font-size: 13px;
    word-spacing: 1px;
    margin-right: 50px;
  }
  .backheadimage ul li::before {
    left: 0.6em;
    font-size: 30px;
  }
}
@media (max-width: 992px) {
  .content .backheadimage {
    height: 160%;
  }
  .backheadimage h2 {
    width: 60%;
    top: 180px;
    margin-right: 8%;
    line-height: 2;
  }
  .backheadimage .row1 {
    width: 70%;
    top: 180px;
    z-index: 11;
    margin-right: 8%;
  }
  .backheadimage ul {
    word-spacing: 1px;
    margin-right: 0px;
  }
  .backheadimage ul li::before {
    left: 0.6em;
    margin-top: 1em;
    font-size: 30px;
    top: 10px;
  }
  .backheadimage .choose {
    width: 30%;
    height: auto;
  }
  .backheadimage .choose img {
    max-width: 50%;
  }
  .bt button {
    width: 50%;
  }
  .priceGroup {
    text-align: center;
  }
}
@media (max-width: 768px) {
  .backheadimage .row1 {
    width: 70%;
    top: 140px;
  }
  .backheadimage ul {
    word-spacing: 1px;
    margin-right: 0px;
    line-height: 1;
  }
  .backheadimage h2 {
    top: 140px;
  }
  .boxses {
    margin: 0px;
  }
  .bt button {
    width: 80%;
  }
  .backheadimage .choose {
    width: 40%;
    height: auto;
  }
  .backheadimage .choose p {
    font-size: 13px;
    margin: 0;
  }
}
@media (max-width: 576px) {
  .topic1 {
    margin-bottom: 80px;
  }
  .content .backheadimage {
    height: 190%;
  }
  .backheadimage h2 {
    top: 130px;
    left: 10px;
    width: 58%;
    word-spacing: 3px;
    margin-right: 8%;
  }
  .backheadimage ul {
    word-spacing: 1px;
    margin-right: 0px;
    padding-right: 10px;
  }
  .backheadimage ul li::before {
    font-size: 20px;
    top: 4px;
  }
  .backheadimage .choose {
    margin: 0px 20px 20px 20px;
    width: 50%;
    height: auto;
  }
  .backheadimage .choose .img-fluid {
    width: 80%;
  }
  .hasBox1,
  .hasBox2 {
    padding-top: 160px;
    margin: 30px;
  }
  .request-a-price p {
    font-size: 1rem;
  }
  .titleBox {
    display: block !important;
    text-align: center;
    font-size: 14px;
  }
  .request-a-consultation i {
    font-size: 18px;
  }
  .request-a-consultation p {
    font-size: 18px;
  }
  .request-a-consultation {
    padding: 10px;
  }
  .request-a-consultation a {
    top: 0px;
  }
  .form-check label {
    font-size: 14px;
  }
  .form-check label span {
    font-size: 12px;
  }
  .bt {
    padding: 0;
  }
  .bt button {
    width: 100%;
  }
  .backheadimage .choose p {
    font-family: fontm;
    font-size: 13px;
    margin: 0;
  }
}
@media (max-width: 300px) {
  .topic1 {
    margin-bottom: 200px;
  }
}
@media (max-width: 576px) and (min-height: 650px) {
  .hasBox1,
  .hasBox2 {
    padding-top: 120px;
  }
}
@media (max-width: 770px) and (min-height: 1020px) {
  .topic1 {
    margin-bottom: 0px;
  }
}
@media (max-width: 1050px) and (min-height: 1250px) {
  .topic1 {
    margin-bottom: 0px;
  }
  .content .backheadimage .choose {
    width: 35%;
    height: 300px;
    padding-top: 40px;
    top: 400px;
  }
  .backheadimage .choose p {
    font-size: 18px;
    margin-top: 30px;
  }
  .backheadimage h2 {
    top: 260px;
  }
  .backheadimage .row1 {
    top: 290px;
  }
}
</style>