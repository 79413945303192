
import axios from './axios'


class Request {

    async home() {
        return await axios.get('/home');
    }

    async ourSuccess() {
        return (await axios.get('/success-page')).data;
    }

    async OurServices() {
        return (await axios.get('/our-services')).data;
    }

    async socialMediaLinks() {
        return await axios.get('social-media');
    }

    async jobs() {
        return (await axios.get('/jobs')).data;
    }


    async financing() {
        return await axios.get('/financing');
    }

    async applications() {
        return (await axios.post('/applications')).data;
    }

    async policy() {
        return (await axios.get('/policy')).data;
    }
}


export default new Request;