<template>
  <div class="content">
    <div class="backheadimage">
      <div class="container headerCont">
        <img
          id="aboutImage"
          src="@/assets/imags/about_us.svg"
          class="img-fluid animate__animated animate__fadeInBottomRight"
          width="450"
          height="370"
        />
        <h2 class="animate__animated animate__fadeInBottomRight">نجاحنا</h2>
      </div>
    </div>

    <section class="topic2 animate__animated animate__fadeInBottomRight">
      <div class="container">
        <div class="row">
          <div v-html="content" class="support-companies"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import request from "../http/Request";

export default {
  data() {
    return {
      content: "",
    };
  },

  async created() {
    this.content = (await request.ourSuccess()).content;
  },
};
</script>

<style scoped>
.content .backheadimage {
  background-image: url(../assets/imags/Group_2331.svg);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 108%;
  min-height: 700px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
}
.content .backheadimage .headerCont {
  position: relative;
  top: 22%;
  background-size: contain;
}
.content .backheadimage .headerCont img {
  position: relative;
  margin: 20px;
  right: 4%;
  background-size: contain;
}
.backheadimage .container h2 {
  color: white;
  text-align: right;
  position: absolute;
  top: 40%;
  right: 20%;
  font-family: fontm;
  font-size: 30px;
}
.topic2 {
  text-align: center;
  margin-bottom: -20px;
}
.topic2 h3 {
  font-family: fontB;
  font-size: 32px;
  margin-bottom: 20px;
}
.topic2 p {
  font-family: fontm;
  font-size: 18px;
}

.support-companies {
  margin-bottom: 50px;
}

@media (min-width: 1367px) {
  .content .backheadimage img {
    width: 580px;
    height: 430px;
  }
}
@media (max-width: 992px) {
  .content .backheadimage {
    height: 180%;
  }
  .backheadimage .headerCont h2 {
    top: 0%;
    right: auto;
  }
}
@media (max-width: 768px) {
  .backheadimage .headerCont h2 {
    top: 0%;
    right: auto;
  }
  .content .backheadimage .headerCont {
    top: 22%;
    background-size: contain;
  }
  .content .backheadimage .headerCont img {
    position: static;
    right: 0%;
    margin: auto;
    margin-top: 80px;
  }
}
@media (max-width: 576px) {
  .backheadimage .headerCont h2 {
    top: 0%;
    right: 28%;
  }
  .content .backheadimage .headerCont {
    top: 18%;
  }
  .content .backheadimage .headerCont img {
    position: static;
    width: 100%;
    height: 280px;
    margin: auto;
    margin-top: 60px;
    padding-top: 60px;
  }
}
</style>