
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
Vue.config.productionTip = false

import "./assets/css/bootstrap.css";
import "./assets/css/share.css";
import 'animate.css';
import "./assets/font-awesome/css/font-awesome.css";

import 'jquery'
import 'popper.js'
import 'bootstrap'

import './http/axios'

import "./assets/js/main.js"

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
