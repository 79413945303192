import Vue from 'vue'
import VueRouter from 'vue-router'

// views
import Home from '../views/Home.vue'
import OurSuccess from '../views/OurSuccess.vue'
import Financing from '../views/Financing.vue'
import Employment from '../views/Employment.vue'
import Services from '../views/Services.vue'
import Thanks from '../views/Thanks.vue'
import Policy from '../views/Policy.vue'
import PageNotFound from '../views/PageNotFound.vue'

Vue.use(VueRouter);

export default new VueRouter({
    routes: [
        { path: '/', component: Home, name: 'home' },
        { path: '/our-success', component: OurSuccess, name: 'about' },
        { path: '/financing', component: Financing, name: 'our-works' },
        { path: '/employment', component: Employment, name: 'employment' },
        { path: '/services', component: Services, name: 'services' },
        { path: '/thank-you', component: Thanks, name: 'thank-you' },
        { path: "/policy", component: Policy, name: 'policy' },
        { path: "*", component: PageNotFound, name: 'not-found' },
    ],
    mode: 'history',
});