<template>
  <div id="app">
    <section class="topic1" id="topic1">
      <header :class="{ headerChang: whiteNave }">
        <nav
          class="navbar navbar-expand-lg"
          :class="{ navbarChange: whiteNave }"
        >
          <div class="container">
            <a class="navbar-brand" href="/">
              <img
                v-if="whiteNave || homePage"
                src="@/assets/imags/redLogo.svg"
                width="250px"
                height="60px"
                class="logo"
              />
              <img
                v-else
                src="@/assets/imags/logo-nav.svg"
                width="250px"
                height="60px"
                class="logo"
              />
            </a>
            <button
              class="navbar-toggler"
              :class="{ barsChange: whiteNave }"
              type="button"
              data-toggle="collapse"
              data-target="#main-bar"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span><i class="fa fa-bars" style="font-size: 44px"></i></span>
            </button>
            <div
              class="collapse navbar-collapse w-100 order-1 order-lg-0"
              id="main-bar"
            >
              <ul class="navbar-nav">
                <li class="nav-item" @click="closeMenu">
                  <router-link
                    class="nav-link"
                    :class="{
                      navbarColorChange: whiteNave,
                      'black-color': homePage,
                    }"
                    id="home"
                    to="/"
                    >الرئيسية</router-link
                  >
                  <div v-if="currentRouteName != 'policy'">
                    <div class="d-none d-lg-block d-xl-block"></div>
                  </div>
                </li>
                <li class="nav-item" @click="closeMenu">
                  <router-link
                    class="nav-link"
                    :class="{
                      navbarColorChange: whiteNave,
                      'black-color': homePage,
                    }"
                    id="about"
                    to="/our-success"
                  >
                    نجاحنا
                  </router-link>
                </li>

                <li class="nav-item" @click="closeMenu">
                  <router-link
                    class="nav-link"
                    :class="{
                      navbarColorChange: whiteNave,
                      'black-color': homePage,
                    }"
                    black-color
                    id="services"
                    to="/services"
                  >
                    خدماتنا</router-link
                  >
                </li>
                <li class="nav-item" @click="closeMenu">
                  <router-link
                    class="nav-link"
                    :class="{
                      navbarColorChange: whiteNave,
                      'black-color': homePage,
                    }"
                    id="our-works"
                    to="/financing"
                  >
                    التمويل
                  </router-link>
                </li>
                <li class="nav-item" @click="closeMenu">
                  <router-link
                    class="nav-link"
                    :class="{
                      navbarColorChange: whiteNave,
                      'black-color': homePage,
                    }"
                    id="employment"
                    to="/employment"
                    >التوظيف</router-link
                  >
                </li>
                <li class="nav-item active" @click="closeMenu">
                  <a
                    class="nav-link"
                    :class="{
                      navbarColorChange: whiteNave,
                      'black-color': homePage,
                    }"
                    id="nav6"
                    href="#footer"
                    >اتصل بنا <span class="sr-only">(current)</span></a
                  >
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </section>

    <router-view />

    <!--modal -->
    <ContactUsModal />
    <!--modal -->

    <section class="footer" id="footer">
      <div class="flixFooter">
        <div class="saide d-none d-lg-block d-xl-block"></div>
        <div class="backForm">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-2 d-flex justify-content-end">
                <p>تواصل معنا</p>
              </div>
              <form @submit.prevent="sendMessage" class="mainForm col-lg-10">
                <div class="row">
                  <div class="p-input col-lg-3">
                    <input
                      v-model="name"
                      type="text"
                      placeholder="أدخل الإسم"
                      class="input form-control"
                    />
                    <div class="error text-danger" v-if="errors && errors.name">
                      {{ errors.name[0] }}
                    </div>
                  </div>
                  <div class="p-input col-lg-3">
                    <input
                      v-model="email"
                      type="email"
                      placeholder="أدخل البريد الإلكتروني"
                      class="input form-control"
                    />
                    <div
                      class="error text-danger"
                      v-if="errors && errors.email"
                    >
                      {{ errors.email[0] }}
                    </div>
                  </div>
                  <div class="p-input col-lg-3">
                    <input
                      v-model="phone_number"
                      type="phone_number"
                      placeholder="أدخل رقم الجوال"
                      class="input form-control"
                    />
                    <div
                      class="error text-danger"
                      v-if="errors && errors.phone_number"
                    >
                      {{ errors.phone_number[0] }}
                    </div>
                  </div>
                  <div class="p-inputLast col-lg-10">
                    <input
                      v-model="message"
                      type="text"
                      class="lastInput form-control"
                      placeholder="أدخل نص الرسالة"
                    />
                    <div
                      class="error text-danger"
                      v-if="errors && errors.message"
                    >
                      {{ errors.message[0] }}
                    </div>
                  </div>

                  <div class="col-lg-10 d-flex justify-content-center">
                    <button type="submit">ارسال</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="arrow">
          <a href="#topic1"
            ><span><img src="@/assets/imags/arrow.png" /> </span
          ></a>
        </div>
      </div>
      <div class="backFooter">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-4 col-sm-12 d-flex justify-content-center">
              <img
                src="@/assets/imags/logo-nav.svg"
                width="220px"
                height="50px"
              />
            </div>
            <div class="impLink1 col-lg-4 col-sm-12">
              <div class="row">
                <div class="impLink col-lg-12 d-flex justify-content-start">
                  <p>الروابط الهامة</p>
                </div>
                <div class="col-lg-5 col-sm-12 d-flex justify-content-start">
                  <a href="/our-success">
                    <p>نجاحنا</p>
                  </a>
                </div>
                <div class="col-lg-7 col-sm-12 d-flex justify-content-start">
                  <a href="/employment">
                    <p>التوظيف</p>
                  </a>
                </div>
                <div class="col-lg-5 col-sm-12 d-flex justify-content-start">
                  <a href="/services">
                    <p>خدماتنا</p>
                  </a>
                </div>
                <div class="col-lg-7 col-sm-12 d-flex justify-content-start">
                  <a href="#footer">
                    <p>إتصل بنا</p>
                  </a>
                </div>
                <div class="col-lg-5 col-sm-12 d-flex justify-content-start">
                  <a href="/financing">
                    <p>التمويل</p>
                  </a>
                </div>

                <div class="col-lg-7 col-sm-12 d-flex justify-content-start">
                  <a href="/policy">
                    <p>سياسة الخصوصية</p>
                  </a>
                </div>

                <div class="col-lg-5 col-sm-12 d-flex justify-content-start">
                  <a href="#"> </a>
                </div>
              </div>
            </div>
            <div class="impLink1 col-lg-4 col-sm-12">
              <div class="row">
                <div class="impLink col-lg-12 d-flex justify-content-start">
                  <p>مواقع التواصل الإجتماعي</p>
                </div>
                <div class="vv d-flex justify-content-center">
                  <div class="col-lg-4 d-flex justify-content-start social">
                    <a target="_blank" :href="instagram_url"
                      ><i class="fa fa-instagram"></i
                    ></a>
                  </div>
                  <div class="col-lg-4 d-flex justify-content-start social">
                    <a target="_blank" :href="twitter_url"
                      ><i class="fa fa-twitter"></i
                    ></a>
                  </div>
                  <div class="col-lg-4 d-flex justify-content-start social">
                    <a target="_blank" :href="linkedin_url"
                      ><i class="fa fa-linkedin"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import $ from "jquery";
import request from "./http/Request";
import ContactUsModal from "./components/ContactUsModal";
import axios from "./http/axios";

export default {
  components: {
    ContactUsModal,
  },
  data() {
    return {
      facebook_url: "",
      twitter_url: "",
      instagram_url: "",
      linkedin_url: "",
      name: "",
      email: "",
      phone_number: "",
      message: "",
      errors: {},
    };
  },
  methods: {
    sendMessage() {
      axios
        .post("contact", {
          name: this.name,
          email: this.email,
          message: this.message,
          phone_number: this.phone_number,
        })
        .then(() => {
          document.querySelector("form").reset();
          this.errors = {};
          this.name = "";
          this.email = "";
          this.message = "";
          this.phone_number = "";
          $("#contactUsModal").modal("show");
        })
        .catch((errors) => {
          if (errors.response.status == 422) {
            this.errors = errors.response.data.errors;
          }
        });
    },
    closeMenu() {
      $(".navbar-collapse").collapse("hide");
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    whiteNave() {
      if (this.currentRouteName == "thank-you") {
        return true;
      }
      if (this.currentRouteName == "not-found") {
        return true;
      }

      return false;
    },

    homePage() {
      return this.currentRouteName == "home";
    },
  },
  async created() {
    const links = (await request.socialMediaLinks()).data;

    this.facebook_url = links.facebook_url;
    this.twitter_url = links.twitter_url;
    this.instagram_url = links.instagram_url;
    this.linkedin_url = links.linkedin_url;

    // animation for nav bar need refactor.
    var x = window.matchMedia("(min-width: 992px)");

    $(document).on("click", ":focus", function () {
      if (x.matches) {
        var c = this.id;
        if (
          c == "home" ||
          c == "about" ||
          c == "employment" ||
          c == "services" ||
          c == "our-works"
        ) {
          var destination = $("#" + c).offset().left;

          $(".nav-item div div").offset({ left: destination + 12 });

          if (c !== "home") {
            $(".nav-item div div").css("background-color", "white");
          } else $(".nav-item div div").css("background-color", " #D21241");
        }
      }
    });
  },
  mounted() {
    // code

    // animation for nav bar need refactor.
    setTimeout(() => {
      var x = window.matchMedia("(min-width: 992px)");

      if (x.matches) {
        let destination = $("#" + this.currentRouteName).offset().left;

        $(".nav-item div div").offset({ left: destination + 12 });

        if (this.currentRouteName !== "home") {
          $(".nav-item div div").css("background-color", "white");
        } else $(".nav-item div div").css("background-color", " #D21241");
      }
    }, 300);
  },
};
</script>
<style scoped>
.black-color {
  color: #313131 !important;
}
</style>