
<template>
  <div class="content">
    <div class="backheadimage">
      <div class="container headerCont">
        <img
          src="@/assets/imags/jobs.svg"
          class="animate__animated animate__fadeInBottomRight"
          width="450"
          height="370"
        />
        <h2 class="animate__animated animate__fadeInBottomRight">
          تنتظرك فرص الابداع و الابتكار مع فريق أصداء
        </h2>
      </div>
    </div>
    <div class="work">
      <p>إنضم لفريق أصداء</p>
    </div>
    <section class="topic4">
      <form
        @submit.prevent="store"
        class="mainForm2 needs-validation"
        novalidate
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-5 offset-lg-2 mb-4">
                  <label class="form-check-label" for="name"> الاسم</label>
                  <input
                    v-model="name"
                    type="text"
                    class="form-control"
                    id="name"
                    name="name"
                  />
                  <div class="error text-danger" v-if="errors && errors.name">
                    {{ errors.name[0] }}
                  </div>
                </div>

                <div class="col-lg-5 mb-4">
                  <label class="form-check-label" for="email"
                    >البريد الالكتروني</label
                  >
                  <input
                    v-model="email"
                    type="email"
                    class="form-control"
                    id="email"
                    name="email"
                  />
                  <div class="error text-danger" v-if="errors && errors.email">
                    {{ errors.email[0] }}
                  </div>
                </div>

                <div class="col-lg-5 offset-lg-2 mb-4">
                  <label class="form-check-label" for="phone_number">
                    رقم الجوال</label
                  >
                  <input
                    v-model="phone_number"
                    type="text"
                    class="form-control"
                    id="phone_number"
                    name="phone_number"
                  />
                  <div
                    class="error text-danger"
                    v-if="errors && errors.phone_number"
                  >
                    {{ errors.phone_number[0] }}
                  </div>
                </div>

                <div class="col-lg-5 mb-4">
                  <label class="form-check-label" for="Linkedin_account"
                    >حساب Linkedin</label
                  >
                  <input
                    v-model="linkedin"
                    type="url"
                    class="form-control"
                    id="Linkedin_account"
                    name="Linkedin_account"
                  />
                  <div
                    class="error text-danger"
                    v-if="errors && errors.linkedin"
                  >
                    {{ errors.linkedin[0] }}
                  </div>
                </div>

                <div class="col-lg-5 offset-lg-2 mb-4">
                  <label class="form-check-label" for="phone_number">
                    مجال الوظيفة
                  </label>
                  <select v-model="job_id" id="jop_type">
                    <i class="fa fa-angle-up"></i>
                    <option v-for="job in jobs" :value="job.id" :key="job.id">
                      {{ job.name }}
                    </option>
                  </select>
                  <div class="error text-danger" v-if="errors && errors.job_id">
                    {{ errors.job_id[0] }}
                  </div>
                </div>

                <div class="col-lg-5 mb-4">
                  <label for="cv">ارفاق السيرة الذاتية</label>

                  <div class="file-up">
                    <input type="file" id="fileUpload" />
                    <span id="fileName"></span>
                    <button type="button" id="customButton">اختيار ملف</button>
                  </div>

                  <p>الملفات المسموحة ( PDF ,PNG , JPG , DOCX )</p>
                  <div
                    class="error text-danger"
                    v-if="errors && errors.cv_file"
                  >
                    {{ errors.cv_file[0] }}
                  </div>
                </div>

                <div class="col-lg-5 mb-4">
                  <vue-recaptcha
                    :sitekey="sitekey"
                    :loadRecaptchaScript="true"
                    language="ar"
                    @verify="onVerify"
                    @expired="onExpired"
                  ></vue-recaptcha>
                  <div class="error text-danger" v-if="showRobotError">
                    يرجى التحقق أولا
                  </div>
                </div>
              </div>
            </div>

            <button type="submit" name="button" class="fbtn">ارسال</button>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import request from "../http/Request";
import axios from "../http/axios";
import $ from "jquery";
import VueRecaptcha from "vue-recaptcha";

export default {
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      name: "",
      email: "",
      phone_number: "",
      linkedin: "",
      job_id: "",
      jobs: [],
      errors: {},
      sitekey: process.env.VUE_APP_SITEKEY,
      robot: true,
      showRobotError: false,
    };
  },
  methods: {
    store() {
      if (this.robot) {
        this.showRobotError = true;
        return;
      }
      let data = new FormData();

      data.append("name", this.name);
      data.append("email", this.email);
      data.append("phone_number", this.phone_number);
      data.append("linkedin", this.linkedin);
      data.append("job_id", this.job_id);
      let cv = document.getElementById("fileUpload").files[0];
      if (cv) {
        data.append("cv_file", cv);
      }

      let settings = { headers: { "content-type": "multipart/form-data" } };

      axios
        .post("applications", data, settings)
        .then(() => {
          document.querySelector("form").reset();
          document.querySelector("#fileName").textContent = "";
          this.errors = {};
          this.name = "";
          this.email = "";
          this.phone_number = "";
          this.linkedin = "";
          this.job_id = "";
          $("#contactUsModal").modal("show");
        })
        .catch((errors) => {
          if (errors.response.status == 422) {
            this.errors = errors.response.data.errors;
          }
        });
    },
    onVerify() {
      this.robot = false;
      this.showRobotError = false;
    },
    onExpired() {
      this.robot = true;
    },
  },
  async created() {
    this.jobs = (await request.jobs()).data;
  },
  mounted() {
    document
      .getElementById("customButton")
      .addEventListener("click", function () {
        document.getElementById("fileUpload").click(); // trigger the click of actual file upload button
      });

    document.getElementById("fileUpload").addEventListener(
      "change",
      function () {
        var fullPath = this.value; // fetched value = C:\fakepath\fileName.extension
        var fileName = fullPath.split(/(\\|\/)/g).pop(); // fetch the file name
        document.getElementById("fileName").innerHTML = fileName; // display the file name
      },
      false
    );
  },
};
</script>

<style scoped>
.content .backheadimage {
  background-image: url(../assets/imags/Group_2331.svg);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 108%;
  min-height: 700px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
}

.content .backheadimage .headerCont {
  position: relative;
  top: 22%;
  /*left:140px;*/
  background-size: contain;
}
.content .backheadimage .headerCont img {
  position: relative;
  margin: 20px;
  right: 4%;
  background-size: contain;
}

.backheadimage .container h2 {
  color: white;
  text-align: right;
  position: absolute;
  top: 40%;
  right: -3px;
  font-family: fontm;
  font-size: 28px;
}

/*  header  */

/*wework*/

.work p {
  margin: auto;
  color: #d21241;
  font-family: fontb;
  font-size: 32px;
}

.work {
  display: flex;
  width: 42%;
  margin: auto;
  margin-bottom: 100px;
}

.work span {
  width: 28%;
  height: 3px;
  background-color: #d8d8d8;
  border-radius: 40px;
  margin-top: 16px;
}

/*wework*/

/*slaider*/

.topic4 .mainForm2 {
  width: 70%;
  margin: auto;
  text-align: right;
  font-family: fontl;
  font-size: 20px;
  color: #a8a8a8;
}

.topic4 .mainForm2 input:focus,
.topic4 .mainForm2 select:focus {
  outline: none;
  box-shadow: 1px 1px 2px 3px rgba(242, 69, 111, 0.2);
}

.topic4 .mainForm2 input {
  border: 0;
  height: 50px;
  border-radius: 5px;
  box-shadow: 2px 0px 8px 2px rgba(168, 168, 168, 0.2);
}

.topic4 .mainForm2 select {
  width: 100%;
  height: 50px;
  border: 0;
  border-radius: 5px;
  box-shadow: 2px 0px 8px 2px rgba(168, 168, 168, 0.2);
}

.text-danger {
  font-size: 12px;
}

.topic4 .mainForm2 .file-up {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  box-shadow: 2px 0px 8px 2px rgba(168, 168, 168, 0.2);
  padding: 10px;
  text-align: left;
  margin-bottom: 10px;
}

.topic4 .mainForm2 p {
  font-family: fontl;
  font-size: 14px;
  text-align: right;
  padding-top: 2px;
  margin-bottom: 2px;
}

#fileUpload {
  display: none; /* do not display the actual file upload button */
}

#customButton {
  /* style the fake upload button */
  background: #fcedef;
  color: #d21241;
  font-size: 14px;
  font-family: fontm;
  border: 0;
}

.mainForm2 button.fbtn {
  color: white;
  width: 30%;
  height: 45px;
  border-radius: 50px;
  font-family: fontm;
  font-size: 18px;
  background-image: linear-gradient(
    to right,
    rgba(106, 9, 33, 1),
    rgba(205, 18, 63, 1)
  );
  border: 0;
  margin: auto;
  position: relative;
  top: 60px;
}
.mainForm2 button.fbtn:hover {
  background-image: linear-gradient(
    to left,
    rgba(139, 7, 7, 1),
    rgba(216, 53, 92, 1)
  );
}

.mainForm2 button.fbtn:focus {
  background-image: linear-gradient(
    to left,
    rgba(139, 7, 7, 1),
    rgba(216, 53, 92, 1)
  );
}

select {
  color: #9e9e9e;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 24px;
  background: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>')
    4% 50% no-repeat #fff;

  margin-top: 10px;
}

.modal-content .btn-secondary {
  color: white;
  width: 40%;
  height: 45px;
  border-radius: 50px;
  font-family: fontm;
  font-size: 18px;
  background-image: linear-gradient(
    to right,
    rgba(139, 7, 7, 1),
    rgba(216, 53, 92, 1)
  );
  border: 0;
  margin: auto;
  margin-bottom: 30px;
}

.modal-dialog {
  max-width: 660px;
}

@media (min-width: 1367px) {
  .content .backheadimage img {
    width: 550px;
    height: 450px;
  }
}

@media (max-width: 1200px) {
  .who-are-we div {
    width: 15%;
    height: 28px;
  }

  .topic4 .topic4-back-img > :first-child {
    padding-right: 100px;
  }

  .backheadimage h2 {
    right: 5%;
    font-size: 28px;
  }
}

@media (max-width: 992px) {
  .content .backheadimage {
    height: 180%;
  }

  .backheadimage .headerCont h2 {
    top: 0%;
    right: auto;
    font-size: 24px;
  }

  .content .backheadimage .headerCont img {
    top: 60px;
  }

  .work {
    width: 50%;

    margin-top: 180px;
  }

  .work p {
    font-size: 30px;
  }

  .topic4 .mainForm2 {
    width: 70%;

    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .backheadimage .headerCont h2 {
    top: 0%;
    right: auto;
  }

  .content .backheadimage .headerCont {
    top: 22%;

    background-size: contain;
  }
  .content .backheadimage .headerCont img {
    position: static;

    right: 0%;

    margin: auto;
    margin-top: 80px;
  }

  .work {
    width: 70%;

    margin-top: 180px;
  }

  .mainForm2 button.fbtn {
    width: 40%;
    height: 45px;
    font-size: 12px;
    top: 0px;
  }
}

@media (max-width: 576px) {
  .backheadimage .headerCont h2 {
    top: 0%;
    width: 80%;
    text-align: right;
  }

  .content .backheadimage .headerCont {
    top: 18%;
  }
  .content .backheadimage .headerCont img {
    position: static;
    width: 100%;
    height: 280px;

    margin: auto;
    margin-top: 60px;
    padding-top: 60px;
  }

  .work {
    width: 90%;

    margin-top: 100px;
  }

  .work p {
    font-size: 20px;
  }

  .topic4 .mainForm2 {
    width: 90%;
  }
}

/* animation*/

.backheadimage {
  animation-delay: 0.4s;
}

.backheadimage img {
  animation-delay: 0.4s;
}
</style>